<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      drawTiming: null,
      cdata: {
        year: null,
        weekCategory: [],
        radarData: [],
        radarDataAvg: [],
        maxData: 6000,
        weekMaxData: [],
        weekLineData: []
      }
    }
  },
components: {
    Chart,
  },
  mounted () {
    this.drawTimingFn();
  },
  beforeDestroy () {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn () {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, 6000);
    },
    setData () {
      // 清空轮询数据
      this.cdata.weekCategory = [];
      this.cdata.weekMaxData = [];
      this.cdata.weekLineData = [];
      this.cdata.radarData = [];
      this.cdata.radarDataAvg = [];
      let dateBase = new Date();
      this.cdata.year = dateBase.getFullYear();
      this.cdata.month = dateBase.getMonth() + 1;
      this.cdata.day = dateBase.getDate();
      // 周数据
      for (let i = 0; i < 12; i++) {
        // 日期
        let monthtruckdata = ['2021/04','2021/05','2021/06','2021/07','2021/08','2021/09','2021/10','2021/11','2021/12','2022/01','2022/02','2022/03']
        this.cdata.weekCategory.push(monthtruckdata[i])

        // 折线图数据
        let distancedata = [5796,3977,2970,2410,1215,2131,2221,2448,3649,2431,4581,3188];
        this.cdata.weekMaxData.push(this.cdata.maxData);
        for (let j = 0; j < 12; j++){
        this.cdata.weekLineData.push(distancedata[j]);
        }
      }

    }
  }
};
</script>

<style lang="scss" scoped>
</style>