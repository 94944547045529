<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        xData: ["长春基地", "济宁基地", "重庆基地", "长沙基地", "济南基地", "随州基地"],
        seriesData: [
          { value: 9, name: "长春基地" },
          { value: 8, name: "济宁基地" },
          { value: 7, name: "重庆基地" },
          { value: 6, name: "长沙基地" },
          { value: 5, name: "济南基地" },
          { value: 4, name: "随州基地" }
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>