<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        xData: ["注册司机", "认证司机", "签约司机", "缴纳押金司机", "流失司机"],
        seriesData: [
          { value: 3030, name: "注册司机" },
          { value: 3695, name: "认证司机" },
          { value: 2151, name: "签约司机" },
          { value: 1331, name: "缴纳押金司机" },
          { value: 262, name: "流失司机" },
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>