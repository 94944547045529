<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      cdata: {
        category: [
          "重庆红岩",
          "长春一汽",
          "济南重汽",
          "济宁重汽",
          "陕汽重卡"
        ],
        barData: [
          90,
          81,
          75,
          93,
          86,
          77
        ],
      }
    };
  },
  components: {
    Chart,
  },
  mounted () {
    let that = this
    setTimeout(()=>{
      that.cdata = {
        category: [
          "重庆红岩",
          "长春一汽",
          "济南重汽",
          "济宁重汽",
          "陕汽重卡"
        ],
        barData: [
          90,
          81,
          75,
          93,
          86,
          77
        ],
      }
    }, 0)
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
</style>