<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <p class="ml-3 colorBlue fw-b fs-xl">{{ item.title }}</p>
        <div>
          <dv-digital-flop  
            class="dv-dig-flop ml-1 mt-2 pl-3"
            :config="item.number"
          />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1 pl-3">各基地签约司机数</span>
        <dv-scroll-ranking-board class="dv-scr-rank-board mt-1" :config="ranking" />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>本月司机转化率</span>
          <CenterChart
            :id="rate[0].id"
            :tips="rate[0].tips"
            :colorObj="rate[0].colorData"
          />
        </div>
        <div class="item bg-color-black">
          <span>本月司机增长率</span>
          <CenterChart
            :id="rate[1].id"
            :tips="rate[1].tips"
            :colorObj="rate[1].colorData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterChart from '@/components/echart/center/centerChartRate'
export default {//文字翻牌器
  data() {
    return {
      titleItem: [
        {
          title: '本月注册司机',
          number: {
            number: [1364],
            toFixed: 1,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 23
            }
          }
        },
        {
          title: '缴纳押金司机',
          number: {
            number: [170],
            toFixed: 1,
            textAlign: 'left',//对齐
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '本月流失司机',
          number: {
            number: [11],
            toFixed: 1,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '本月看单司机',
          number: {
            number: [1269],
            toFixed: 1,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '本月抢单司机',
          number: {
            number: [451],
            toFixed: 1,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '司机净增长',
          number: {
            number: [1535],
            toFixed: 1,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        }
      ],
      ranking: {
        data: [
          {
            name: '长春基地',
            value: 28
          },
          {
            name: '济宁基地',
            value: 99
          },
          {
            name: '重庆基地',
            value: 74
          },
          {
            name: '济南基地',
            value: 193
          },
          {
            name: '随州基地',
            value: 35
          },
          {
            name: '北京基地',
            value: 75
          },
          {
            name: '西安基地',
            value: 101
          },
          {
            name: '青岛基地',
            value: 40
          }
        ],
        rowNum: 5,
        carousel: 'single',
        unit: '人'
      },
      water: {
        data: [24, 45],
        shape: 'roundRect',
        formatter: '{value}%',
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: 'centerRate1',
          tips: 30,
          colorData: {
            textStyle: '#3fc0fb',
            series: {
              color: ['#00bcd44a', 'transparent'],
              dataColor: {
                normal: '#03a9f4',
                shadowColor: '#97e2f5'
              }
            }
          }
        },
        {
          id: 'centerRate2',
          tips: 15,
          colorData: {
            textStyle: '#ff9800',
            series: {
              color: ['#faf3a378', 'transparent'],
              dataColor: {
                normal: '#ff9800',
                shadowColor: '#fcebad'
              }
            }
          }
        }
      ]
    }
  },
  components: {
    CenterChart
  }
}
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;
      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 70%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 50%;
      flex-wrap: nowrap;
      .item {
        width: 75%;
        height: 120px;
        span {
          margin-left: 0px;
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
