<template>
  <div>
    <Echart
      id="centreLeft2Chart"
      ref="centreLeft2ChartRef"
      :options="options"
      height="400px"
      width="570px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart';
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        // 设置点的位置(经纬度)
        const geoCoordMap = {
          甘肃: [100.735, 38.7393, 20],
          青海: [96.2377, 35.2652, 20],
          广西: [108.756, 23.7451, 20],
          贵州: [106.559, 26.8033, 20],
          重庆: [107.73, 30.0173, 20],
          北京: [116.389, 39.9488, 20],
          福建: [118.178, 26.408, 20],
          安徽: [117.253, 31.9537, 20],
          广东: [113.72, 23.7924, 20],
          西藏: [88.4137, 31.4515, 20],
          新疆: [85.4253, 41.122, 20],
          海南: [109.825, 19.1865, 20],
          宁夏: [106.038, 37.1762, 20],
          陕西: [108.363, 33.7713, 20],
          山西: [112.389, 37.7586, 20],
          湖北: [112.264, 30.9857, 20],
          湖南: [111.712, 27.6667, 20],
          四川: [102.384, 30.5431, 20],
          云南: [101.661, 24.4603, 20],
          河北: [115.314, 38.5205, 20],
          河南: [113.484, 33.9055, 20],
          辽宁: [123.07, 41.386, 20],
          山东: [118.114, 36.3271, 20],
          天津: [117.347, 39.3708, 20],
          江西: [116.017, 27.6397, 20],
          江苏: [119.942, 32.9844, 20],
          上海: [121.409, 31.0909, 20],
          浙江: [119.97, 29.1084, 20],
          吉林: [126.466, 43.2978, 20],
          内蒙古: [111.623, 41.5938, 20],
          黑龙江: [127.97, 46.8451, 20],
          台湾: [121.0254, 23.5986, 20],
          香港: [114.2578, 22.3242, 20],
          澳门: [113.5547, 22.1484, 20],
        };
        
        let seriesData = [
          {
            name: "甘肃",
          },
          {
            name: "青海",
          },
          {
            name: "广西",
          },
          {
            name: "贵州",
          },
          {
            name: "重庆",
          },
          {
            name: "北京",
          },
          {
            name: "福建",
          },
          {
            name: "安徽",
          },
          {
            name: "广东",
          },
          {
            name: "西藏",
          },
          {
            name: "新疆",
          },
          {
            name: "海南",
          },
          {
            name: "宁夏",
          },
          {
            name: "陕西",
          },
          {
            name: "山西",
          },
          {
            name: "湖北",
          },
          {
            name: "湖南",
          },
          {
            name: "四川",
          },
          {
            name: "云南",
          },
          {
            name: "河北",
          },
          {
            name: "河南",
          },
          {
            name: "辽宁",
          },
          {
            name: "山东",
          },
          {
            name: "天津",
          },
          {
            name: "江西",
          },
          {
            name: "江苏",
          },
          {
            name: "上海",
          },
          {
            name: "浙江",
          },
          {
            name: "吉林",
          },
          {
            name: "内蒙古",
          },
          {
            name: "黑龙江",
          },
          {
            name: "台湾",
          },
          {
            name: "香港",
          },
          {
            name: "澳门",
          },
        ];
        let convertData = function (data) {
          let scatterData = [];
          for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
              scatterData.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value),
              });
            }
          }
          return scatterData;
        };
        this.options = {
          showLegendSymbol: true,
          tooltip: {
            trigger: 'item',
            textStyle: {
              fontSize: 14,
              lineHeight: 22,
            },
            position: point => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20];
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            /*
              formatter: params => {
                return `<div style=""> ... </div>`
              }
            */
          },
          visualMap: {
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
              color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)'],
            },
          },
          // 底部背景
          geo: {
            show: false,
            aspectScale: 0.85, //长宽比
            zoom: 1.2,
            top: '8%',
            left: '10%',
            map: 'china',
            roam: false,
            itemStyle: {
              normal: {
                areaColor: 'rgba(0,0,0,0)',
                shadowColor: 'rgba(7,114,204, .8)',
                shadowOffsetX: 5,
                shadowOffsetY: 5,
              },
              emphasis: {
                areaColor: '#00aeef',
              },
            },
          },
          series: [
            {
              name: '空闲司机',
              type: 'map',
              aspectScale: 0.85, //长宽比
              zoom: 1.2,
              mapType: 'china', // 自定义扩展图表类型
              top: '8%',
              left: '10%',
              itemStyle: {
                normal: {
                  color: 'red',
                  areaColor: 'rgba(19,54,162, .5)',
                  borderColor: 'rgba(0,242,252,.3)',
                  borderWidth: 1,
                  shadowBlur: 7,
                  shadowColor: '#00f2fc',
                },
                emphasis: {
                  areaColor: '#4f7fff',
                  borderColor: 'rgba(0,242,252,.6)',
                  borderWidth: 2,
                  shadowBlur: 10,
                  shadowColor: '#00f2fc',
                },
              },
              label: {
                formatter: params => `${params.name}`,
                show: false,
                position: 'insideRight',
                textStyle: {
                  fontSize: 14,
                  color: '#efefef',
                },
                emphasis: {
                  textStyle: {
                    color: '#fff',
                  },
                },
              },
              data: newData,
            },
          ],
        };
        // 重新选择区域
        this.handleMapRandomSelect();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    // 开启定时器
    startInterval() {
      const _self = this;
      // 应通过接口获取配置时间，暂时写死5s
      const time = 2000;
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        _self.reSelectMapRandomArea();
      }, time);
    },
    // 重新随机选中地图区域
    reSelectMapRandomArea() {
      const length = 34;
      this.$nextTick(() => { 
        try {
          const map = this.$refs.centreLeft2ChartRef.chart;
          let index = Math.floor(Math.random() * length);
          while (index === this.preSelectMapIndex || index >= length) {
            index = Math.floor(Math.random() * length);
          }
          map.dispatchAction({
            type: 'mapUnSelect',
            seriesIndex: 0,
            dataIndex: this.preSelectMapIndex,
          });
          map.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: index,
          });
          map.dispatchAction({
            type: 'mapSelect',
            seriesIndex: 0,
            dataIndex: index,
          });
          this.preSelectMapIndex = index;
        } catch (error) {
          console.log(error)
        }
      });
    },
    handleMapRandomSelect() {
      this.$nextTick(() => {
        try {
          const map = this.$refs.centreLeft2ChartRef.chart;
          const _self = this;
          setTimeout(() => {
            _self.reSelectMapRandomArea();
          }, 0);
          // 移入区域，清除定时器、取消之前选中并选中当前
          map.on('mouseover', function (params) {
            clearInterval(_self.intervalId);
            map.dispatchAction({
              type: 'mapUnSelect',
              seriesIndex: 0,
              dataIndex: _self.preSelectMapIndex,
            });
            map.dispatchAction({
              type: 'mapSelect',
              seriesIndex: 0,
              dataIndex: params.dataIndex,
            });
            _self.preSelectMapIndex = params.dataIndex;
          });
          // 移出区域重新随机选中地图区域，并开启定时器
          map.on('globalout', function () {
            _self.reSelectMapRandomArea();
            _self.startInterval();
          });
          this.startInterval();
        } catch (error) {
          console.log(error)
        }
      });
    },
  },
};
</script>
