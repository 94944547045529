<template>
  <div>
    <Echart
      :options="options"
      id="centreRight2Chart1"
      height="200px"
      width="260px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        // 固定样式数据
        this.options = {
          color: [
            "#5470c6",
            "#91cc75",
            "#fac858",
            "#ee6666",
            "#73c0de",
            "#3ba272",
            "#fc8452",
            "#9a60b4"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          //鼠标悬浮提示
          calculable: true,
          legend: {
            orient: "horizontal",
            icon: "circle",
            top: 0,
            x: "center",
            data: newData.xData,
            textStyle: {
              color: "#fff"
            }
          },
          series: [
            {
              name: "联程单占比",
              type: "pie",
              radius: [30, 70],
              center: ["50%", "60%"],
              data: newData.seriesData,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '10',
                  fontWeight: ' normal'
                }
              },              
              labelLine: {
                show: false
              },
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>