<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">本月发车系统派单分析</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ['调度方式', '发车数', '占比'],
        data: [
          ['短驳直发', '0', "<span  class='colorGrass'>0%</span>"],
          ['秒杀', '182', "<span  class='colorRed'>1.74%</span>"],
          ['秒杀>人工', '16', "<span  class='colorGrass'>0.15%</span>"],
          ['强派>人工', '0', "<span  class='colorRed'>0%</span>"],
          ['抢单', '3859', "<span  class='colorGrass'>36.96%</span>"],
          ['抢单变人工', '140', "<span  class='colorRed'>1.34%</span>"],
          ['抢单>人工', '2819', "<span  class='colorGrass'>27.00%</span>"],
          ['人工', '3406', "<span  class='colorRed'>32.62%</span>"],
          ['强派', '0', "<span  class='colorGrass'>0%</span>"],
        ],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: true,
        columnWidth: [50],
        align: ['center']
      }
    }
  },
  components: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
$box-height: 410px;
$box-width: 310px;
#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 310px;
      height: 340px;
    }
  }
}
</style>
