<template>
  <div>
    <Echart
      :options="options"
      id="BottomRight2Chart"
      height="240px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
      // 定义颜色
      colorList: {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#f5b44d"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)"
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)"
            }
          ]
        }
      }
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          title: {
            text: "",
            textStyle: {
              color: "#D3D6DD",
              fontSize: 24,
              fontWeight: "normal"
            },
            subtext: newData.year + "/" + newData.month + "/" + newData.day,
            subtextStyle: {
              color: "#fff",
              fontSize: 16
            },
            top: -10,
            left: 0
          },
          tooltip: {
            trigger: "axis",
            triggerOn: "mousemovelclick",
            axisPointer:{
              type:"line",
              axis:"auto",
              animation:"auto",
            },
            show: "true",
            textstyle:{
              color:"#666",
              fontSize: 14,
            },
            label: {
              show: true,
              backgroundColor: "#7B7DDC"
            },
          },
          grid: {
            left: 90,
            right: 80,
            bottom: 60,
            top: "20%"
          },
          xAxis: {
            type: "category",
            position: "bottom",
            axisLine: true,
            axisLabel: {
              color: "rgba(255,255,255,.8)",
              fontSize: 12,
              formatter: "{value}"
            },
            data: newData.weekCategory
          },
          // 下方Y轴
          yAxis: {
            max: newData.maxData,
            splitNumber: 4,
            axisLine: {
              lineStyle: {
                opacity: 0,
                color: "#B4B4B4",
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#fff",
                opacity: 0.1
              }
            },
            axisLabel: {
              color: "rgba(255,255,255)",
              fontSize: 12,
              formatter: "{value}"
            }
          },
          series: [
            {
              name: "发车量",
              type: "line",
              smooth: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              itemStyle: {
                normal: {
                  color: "#fff"
                }
              },
              lineStyle: {
                normal: {
                  color: this.colorList.linearBtoG,
                  width: 3
                }
              },
              areaStyle: {
                normal: {
                  color: this.colorList.areaBtoG
                }
              },
              data: newData.weekLineData,
              lineSmooth: true,
              markLine: {
                silent: true,
                data: [
                  {
                    type: "average",
                    name: "平均值"
                  }
                ],
                precision: 0,
                label: {
                  normal: {
                    formatter: "平均值: \n {c}"
                  }
                },
                lineStyle: {
                  normal: {
                    color: "rgba(248,211,81,.7)"
                  }
                },
              },
              tooltip: {
                trigger: "anis",
                triggerOn: "mousemovelclick",
                axisPointer:{
                  type:"cross",
                  axis:"auto",
                  animation:"auto",
                },
                show: "fauls",
                textstyle:{
                  color:"#fff",
                  fontSize: 14,
                },
                position: "top",
                formatter: "{value}",
                backgroundColor: "rgba(28,152,232,.2)",
                padding: 6
              },
            },
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>