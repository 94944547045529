<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      drawTiming: null,
      cdata: {
        year: null,
        month: null,
        day: null,
        weekCategory: [],
        radarData: [],
        radarDataAvg: [],
        maxData: 360,
        weekMaxData: [],
        weekLineData: []
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
    this.drawTimingFn();
  },
  beforeDestroy () {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn () {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, 6000);
    },
    setData () {
      // 清空轮询数据
      this.cdata.weekCategory = [];
      this.cdata.weekMaxData = [];
      this.cdata.weekLineData = [];
      this.cdata.radarData = [];
      this.cdata.radarDataAvg = [];
      let dateBase = new Date();
      this.cdata.year = dateBase.getFullYear();
      this.cdata.month = dateBase.getMonth() + 1;
      this.cdata.day = dateBase.getDate();
      // 周数据
      for (let i = 0; i < 30; i++) {
        // 日期
        let date = new Date();
        if((date.getDate()-i) > 0){
        this.cdata.weekCategory.unshift([date.getMonth() + 1, date.getDate()-i].join("/"));
        }
        if((date.getDate()-i) <= 0){
          if(date.getMonth() == 1 || date.getMonth() == 3 || date.getMonth() == 5 || date.getMonth() == 7 || date.getMonth() == 8 || date.getMonth() == 10 || date.getMonth() == 12){
            this.cdata.weekCategory.unshift([date.getMonth() , 31 - i + date.getDate()].join("/"));
          }
          if(date.getMonth() == 4 || date.getMonth() == 6 || date.getMonth() == 9 || date.getMonth() == 11){
            this.cdata.weekCategory.unshift([date.getMonth() , 30 - i + date.getDate()].join("/"));
          }
          if(date.getMonth() == 2){
            this.cdata.weekCategory.unshift([date.getMonth() , 28 - i + date.getDate()].join("/"));
          }
        }

        // 折线图数据
        let distancedata = [21,83,167,101,127,209,171,238,201,210,196,131,144,109,171,155,237,165,130,115,250,217,336,154,139,165,239,181,138,234];
        this.cdata.weekMaxData.push(this.cdata.maxData);
        for (let j = 0; j < 30; j++){
        this.cdata.weekLineData.push(distancedata[j]);
        }
      }

    }
  }
};
</script>

<style lang="scss" scoped>
</style>