<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      cdata: {
        category: [
          "长春基地",
          "济宁基地",
          "重庆基地",
          "济南基地",
          "北京基地",
          "西安基地",
          "青岛基地"
        ],
        lineData: [
          35,
          156,
          40,
          170,
          29,
          87,
          4,
        ],
        barData: [
          6,
          41,
          31,
          29,
          13,
          7,
          3,
        ],
        rateData: []
      }
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    setData () {
      for (let i = 0; i < this.cdata.barData.length ; i++) {
        let rate = 1 - (this.cdata.barData[i] / this.cdata.lineData[i]);
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>