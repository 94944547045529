<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: "甘肃",
          value: 2,
          elseData:{
            name: "123",
            value: 50
            // 这里放置地图 tooltip 里想显示的数据
          },
        },
        {
          name: "青海",
          value: 11,
        },
        {
          name: "广西",
          value: 15,
        },
        {
          name: "贵州",
          value: 19,
        },
        {
          name: "重庆",
          value: 572,
        },
        {
          name: "北京",
          value: 51,
        },
        {
          name: "福建",
          value: 19,
        },
        {
          name: "安徽",
          value: 49,
        },
        {
          name: "广东",
          value: 54,
        },
        {
          name: "西藏",
          value: 5,
        },
        {
          name: "新疆",
          value: 74,
        },
        {
          name: "海南",
          value: 6,
        },
        {
          name: "宁夏",
          value: 5,
        },
        {
          name: "陕西",
          value: 168,
        },
        {
          name: "山西",
          value: 27,
        },
        {
          name: "湖北",
          value: 131,
        },
        {
          name: "湖南",
          value: 215,
        },
        {
          name: "四川",
          value: 62,
        },
        {
          name: "云南",
          value: 17,
        },
        {
          name: "河北",
          value: 278,
        },
        {
          name: "河南",
          value: 141,
        },
        {
          name: "辽宁",
          value: 135,
        },
        {
          name: "山东",
          value: 1425,
        },
        {
          name: "天津",
          value: 35,
        },
        {
          name: "江西",
          value: 28,
        },
        {
          name: "江苏",
          value: 64,
        },
        {
          name: "上海",
          value: 13,
        },
        {
          name: "浙江",
          value: 41,
        },
        {
          name: "吉林",
          value: 1259,
        },
        {
          name: "内蒙古",
          value: 20,
        },
        {
          name: "黑龙江",
          value: 51,
        },
      ]
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>